import React, {useEffect, useState} from 'react';
import {Table, Space} from "antd";
import {useFetching} from "../../hooks/useFetching";
import {Link} from "react-router-dom";
import VerigramService from "../../API/VerigramService";

const List = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.user.id}`}>{record.user.id}</Link>
                </Space>
            )
        },
        {
            title: 'Тип регистрации',
            dataIndex: 'resident',
            key: 'resident'
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Отчество',
            dataIndex: 'middleName',
            key: 'middleName'
        },
        {
            title: 'Дата рождения',
            dataIndex: 'birthDate',
            key: 'birthDate'
        },
        {
            title: 'ИИН',
            dataIndex: 'iin',
            key: 'iin'
        },
        {
            title: 'Пол',
            dataIndex: 'gender',
            key: 'gender'
        },
        {
            title: 'Номер удостоверения личности',
            dataIndex: 'idNumber',
            key: 'idNumber'
        },
        {
            title: 'Место рождения',
            dataIndex: 'birthPlace',
            key: 'birthPlace'
        },
        {
            title: 'Национальность',
            dataIndex: 'nationality',
            key: 'nationality'
        },
        {
            title: 'Этническая принадлежность',
            dataIndex: 'ethnicity',
            key: 'ethnicity'
        },
        {
            title: 'Орган, выдавший документ',
            dataIndex: 'issuer',
            key: 'issuer'
        },
        {
            title: 'Дата выдачи документа',
            dataIndex: 'issueDate',
            key: 'issueDate'
        },
        {
            title: 'Срок действия документа',
            dataIndex: 'dueDate',
            key: 'dueDate'
        },
        {
            title: 'Код государства',
            dataIndex: 'stateCode',
            key: 'stateCode'
        },
        {
            title: 'Тип документа',
            dataIndex: 'docType',
            key: 'docType'
        },
        {
            title: 'Фото',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (text, record) => {
                return (
                    <img src={'data:image/png;base64, ' + record.imageUrl} alt='verigram' style={{width: '100px'}}/>
                )
            }
        }
    ];
    const [verigramList, setVerigramList] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [fetchVerigramList, isVerigramListLoading] = useFetching(async (page, size) => {
        const {data} = await VerigramService.getList(page, size);
        setVerigramList(data?.content);
        const pageable = data?.pageable;
        setPagination({
            current: pageable?.pageNumber + 1,
            pageSize: pagination.pageSize,
            total: data.totalItems
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchVerigramList(pagination.current, pagination.pageSize, null);
    }

    useEffect(() => {
        fetchVerigramList(pagination.current, pagination.pageSize);
    }, []);

    return (
        <>
            <Table 
                className="verigram-table"
                columns={columns}
                dataSource={verigramList}
                rowKey='id'
                loading={isVerigramListLoading}
                pagination={pagination}
                handleTableChange={handleTableChange}/>
        </>
    );
};

export default List;