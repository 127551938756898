import React, {useState} from 'react';
import './Sidebar.css';
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import {Header} from "antd/es/layout/layout";

const {Sider} = Layout;
const {SubMenu} = Menu;

const Sidebar = () => {

    const [collapsed, setCollapsed] = useState(false);

    const menu = [
        {
            title: 'Главная',
            path: '/',
        },
        {
            title: 'Пользователи',
            path: '/users',
        },
        {
            title: 'Заказы',
            path: '/orders',
        },
        {
            title: 'Отзывы',
            path: '/feedbacks',
        },
        {
            title: 'Подтверджённые Отзывы',
            path: '/approved-feedbacks',
        },
        {
            title: 'Платежи',
            path: '/payments',
        },
        {
            title: 'Тарифы Axess',
            path: '/axess/meta-tariffs',
        },
        {
            title: 'Уведомления',
            path: '/notifications',
        },
        {
            title: 'Данные по верификации',
            path: '/verigram',
        }
        // {
        //     title: 'Мерчант',
        //     subItems: [
        //         {
        //             title: 'Заказы',
        //             path: '/merchant/orders'
        //         },
        //         {
        //             title: 'Категории',
        //             path: '/merchant/categories'
        //         },
        //         {
        //             title: 'Товары',
        //             path: '/merchant/products'
        //         },
        //     ]
        // },
        // {
        //     title: 'Прокат',
        //     subItems: [
        //         {
        //             title: 'Заказы',
        //             path: '/rent/orders'
        //         },
        //         {
        //             title: 'Инвентарь',
        //             path: '/rent/items'
        //         }
        //     ]
        // },
        // {
        //     title: 'Заказы в SURI',
        //     path: '/suri/orders',
        // },
        // {
        //     title: 'Бонусы',
        //     subItems: [
        //     ]
        // },
        // {
        //     title: 'Контент',
        //     subItems: [
        //         {
        //             title: 'Онбординг',
        //             path: '/content/onboarding'
        //         },
        //         {
        //             title: 'Новости',
        //             path: '/content/news'
        //         },
        //         {
        //             title: 'Live',
        //             path: '/content/live'
        //         }
        //     ]
        // },
        // {
        //     title: 'Рестораны',
        //     subItems: [
        //         {
        //             title: 'Список',
        //             path: '/restaurants'
        //         },
        //         {
        //             title: 'Бронирование',
        //             path: '/restaurants/reservation'
        //         }
        //     ]
        // },
        // {
        //     title: 'Отели',
        //     subItems: [
        //         {
        //             title: 'Список',
        //             path: '/hotels'
        //         },
        //         {
        //             title: 'Бронирование',
        //             path: '/hotels/booking'
        //         }
        //     ]
        // }
    ];

    return (
        <Sider collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
            <Header className="header">
                <img src="/logo.png" className="sidebar-logo" alt="logo"/>
            </Header>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                {menu.map(item => (!item.subItems
                    ? <Menu.Item key={item.path}>
                        <Link to={item.path}>{item.title}</Link>
                    </Menu.Item>
                    : <SubMenu key={item.title} title={item.title}>
                        {item.subItems.map(subItem =>
                            <Menu.Item key={subItem.path}>
                                <Link to={subItem.path}>{subItem.title}</Link>
                            </Menu.Item>
                        )}
                    </SubMenu>)
                )}
            </Menu>

            <Menu selectable={false} theme="dark" style={{marginTop: '16px'}}>
                <Menu.Item key="swagger-api">
                    <a href="https://api.shymbulak.mimishki.kz/swagger-ui.html" target="_blank" rel="noreferrer">Документация
                        API</a>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default Sidebar;